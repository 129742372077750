import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Sidebar from '../components/Sidebar'

class ContactRoute extends React.Component {
  render() {
    const { title, subtitle } = this.props.data.site.siteMetadata

    return (
      <Layout>
        <div>
          <Helmet>
            <title>{title}</title>
            <meta name="description" content={subtitle} />
          </Helmet>
          <Sidebar {...this.props} />
          <div className="content">
            <div className="content__inner">
              <form method="POST" action="https://formspree.io/info@diehobbyisten.net">
                <div style={{ padding: '10px' }}>
                  <label htmlFor="name">Name:
                    <input type="text" style={{ minWidth: '300px' }} required name="name" placeholder="Dein Name" />
                  </label>
                </div>
                <div style={{ padding: '10px' }}>
                  <label htmlFor="email">E-Mail:
                    <input type="email" style={{ minWidth: '300px' }} name="email" placeholder="Deine E-Mail Adresse" />
                  </label>
                </div>
                <div style={{ padding: '10px' }}>
                  <textarea name="message" style={{ minWidth: '500px', minHeight: '300px' }} required placeholder="Deine Nachricht an die Hobbyisten" />
                </div>
                <input type="hidden" name="_next" value="https://www.diehobbyisten.net/contact-thanks" />
                <input type="hidden" name="_subject" value="Neues Feedback für Die Hobbyisten!" />
                <input type="hidden" name="_language" value="de" />
                <div style={{ padding: '10px' }}>
                  <button type="submit">Abschicken</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ContactRoute

export const pageQuery = graphql`
  query ContactQuery {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        copyrightLink
        menu {
          label
          path
        }
        author {
          name
          email
          twitter
          rss
        }
      }
    }
  }
  `
